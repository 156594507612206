ul.smothscroll {
    position: fixed;
    bottom: 25px;
    right: 30px;
    list-style: none;
    z-index: 99;
}

ul.smothscroll a {
    background-color: rgba(202, 156, 106, 0.7);
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50%;
    display: block;
    text-align: center;
    color: #fff;
    background-size: 200%, 1px;
    background-position: 0;
    border: 2px solid #ca9c6a;
}

ul.smothscroll a:hover {
    background-color: #ca9c6a;
    color: #fff;
}

/* Adjust size on small screens (up to 767px) */
@media (max-width: 767px) {
    ul.smothscroll a {
        width: 30px;
        height: 30px;
        line-height: 25px;
    }
}

/* Hide the entire ul.smothscroll on screens up to 991px */
@media (max-width: 991px) {
    ul.smothscroll {
        display: none;
    }
}
