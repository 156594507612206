.language-switcher {
    position: absolute;
    right: 50px; /* Adjust this value to move it further to the right */
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}

.language-switcher .language-button {
    background: none;
    border: none;
    color: #333;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.language-switcher .fa-globe {
    font-size: 24px; /* Adjust this to make the icon bigger */
    margin-right: 8px; /* Adjust spacing around the icon if needed */
}

.language-dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 25px; /* Adjust this value to move the dropdown lower */
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.language-switcher:hover .language-dropdown-content {
    display: block;
}

.language-dropdown-content button {
    background-color: transparent;
    border: none;
    color: black;
    padding: 10px;
    text-align: left;
    width: 100%;
    cursor: pointer;
}

.language-dropdown-content button:hover {
    background-color: #f1f1f1;
}

/* Responsive styles */
@media (max-width: 768px) {
    .language-switcher {
        margin-right: 15px;
        margin-top: 3px;
    }

    .language-dropdown-content {
        min-width: 80px;
        right: 0;
    }

    .navigation {
        flex-direction: column;
        text-align: center;
    }
}
