/*=====================================================
12. service-single
======================================================*/
.service-single-area {
    padding: 120px 0 0;

    @media (max-width:991px) {
        padding: 90px 0 0;
    }

    @media (max-width:767px) {
        padding: 70px 0 0;
    }

    .service-single-wrap {
        .service-single-title {
            h3 {
                font-weight: 400;
                font-size: 35px;
                line-height: 130.5%;
                margin-bottom: 20px;

                @media(max-width:767px) {
                    font-size: 25px;
                }
            }
        }

        .service-single-item {
            margin-bottom: 40px;

            .service-single-main-img {
                margin-bottom: 30px;
            }

            p {
                color: #5f5c5c;
            }
        }

        .list-widget {
            max-width: 590px;

            ul {
                list-style: none;

                li {
                    padding: 10px 0;
                    padding-left: 35px;
                    position: relative;
                    color: #5f5c5c;

                    &:before {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 25px;
                        height: 25px;
                        line-height: 25px;
                        border-radius: 50%;
                        background: transparentize(#ca9c6a, .9);
                        content: "\e64c";
                        font-family: "themify";
                        text-align: center;
                        color: #ca9c6a;
                        font-size: 14px;
                    }
                }
            }
        }

        .p-details-img {
            margin-bottom: 10px;
        }

        .service-area {
            padding-bottom: 0;

            .service-item {
                padding: 20px;
                box-shadow: 0px 2px 10px 2px rgba(21, 44, 88, .05);
                margin-bottom: 30px;

                img {
                    margin-bottom: 10px;
                }

                @media(max-width:1200px) {
                    padding: 20px 10px;
                }

                h2 {
                    font-weight: 400;
                    font-size: 22px;
                    margin-bottom: 12px;
                    margin-top: 20px;

                    @media(max-width:1400px) {
                        font-size: 19px;
                        margin-bottom: 10px;
                    }

                    @media(max-width:1200px) {
                        font-size: 17px;
                        margin-bottom: 10px;
                    }

                    @media(max-width:991px) {
                        font-size: 22px;
                    }
                }

                p {
                    margin-bottom: 5px;
                }

            }
        }

        .service-contact-area {
            padding: 30px;
            box-shadow: 0px 2px 8px 2px rgba(22, 14, 71, 0.1);
            border-radius: 20px;
            background: #fff;

            .contact-title {
                margin-bottom: 30px;
            }

            .contact-form-area {
                .col {
                    margin-bottom: 30px;
                }

                .form-control {
                    height: 50px;
                    border: 0;
                    border-bottom: 1px solid #ebebeb;
                    border-radius: 0;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                textarea.form-control {
                    height: 120px;
                }

                .errorMessage {
                    margin-top: 5px;
                    color: red;
                }

                .submit-area {
                    .theme-btn {
                        padding: 17px 40px;
                        border: none;
                        background: #ca9c6a;
                        color: #fff;
                        transition: all 0.3s;
                        outline: none;

                        &::before {
                            display: none;
                            position: unset;
                        }
                    }
                }
            }
        }
    }
}